import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StatisticDTO} from '../../model/dto/statistic.dto';
import {AbstractWebservice} from "./abstract.webservice";
import {UrlBuilder} from "../../utils/helpers/url-builder.helper";

@Injectable({
  providedIn: 'root'
})
export class DashboardWebservice extends AbstractWebservice {

  entity = 'dashboard';

  constructor(protected readonly httpClient: HttpClient,
              protected readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getRegisteredByActivities(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {
    const url = new UrlBuilder(this.root)
      .addPathParam('registered-by-activities')
      .addQueryParam('month', date.getMonth() + 1)
      .addQueryParam('year', date.getFullYear())
      .build();
    return this.post<StatisticDTO>(url, listOfIdPlace);
  }

  getRegisteredByGender(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {
    const url = new UrlBuilder(this.root)
      .addPathParam('registered-by-gender')
      .addQueryParam('month', date.getMonth() + 1)
      .addQueryParam('year', date.getFullYear())
      .build();
    return this.post<StatisticDTO>(url, listOfIdPlace);
  }
}
